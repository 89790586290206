.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1vw;
}

.about-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 2vw;
  grid-template-columns: 1fr;
}

.about-message-container {
  display: flex;
  flex-direction: row;
}

.about-label {
  font-weight: 400;
}

.about-label-email {
  font-weight: 500;
  color: cornflowerblue;
  margin-left: 0.5vw;
}
