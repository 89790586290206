.u-full-vw {
  width: 100vw;
}

.u-full-vh {
  height: 100vh;
}

.no-margin {
  margin: 0vw;
}

.demo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  margin: 0vw;
}

.demo-store-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.demo-store-frame {
  display: flex;
  border-right: 0.05vw solid cornflowerblue;
  width: 50vw;
  height: 95vh;
}

.demo-left-vertical-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.demo-right-vertical-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.demo-admin-frame {
  display: flex;
  height: 50vh;
  border-bottom: 0.05vw solid cornflowerblue;
}

.demo-warehouse-frame {
  display: flex;
  height: 50vh;
}

.modal {
  padding-left: 0px !important;
}

.modal-dialog-centered {
  margin: 0.5vw !important;
  max-width: 65vw !important;
  margin: 1.75vw auto !important;
}

.modal-fullscreen {
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
}

.modal-fullscreen .modal-content {
  border: 0 !important;
  border-radius: 0 !important;
}

.modal-content {
  border: 0.1vw solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3vw !important;
}

.modal-header {
  padding: 0vw 1vw !important;
  border-bottom: 0.1vw solid #dee2e6 !important;
  font-size: 1.5vw;
}

.modal-title {
  font-size: 1.5vw !important;
}

.modal-header .btn-close {
  padding: 0.5vw 0.5vw !important;
  margin: -0.5vw -0.5vw -0.5vw auto !important;
  font-size: 1vw !important;
}

.modal-body {
  padding: 0vw !important;
}

.modal-footer {
  padding: 0vw !important;
  border-top: 0.1vw solid #dee2e6 !important;
}

.modal-footer > * {
  margin: 0.25vw !important;
}

.btn-secondary {
  padding: 0vw 0.75vw !important;
  font-size: 1vw !important;
  border: 0.1vw solid transparent !important;
  border-radius: 0.25vw !important;
}
