.navigation-main {
  display: flex;
  border-top: 0.05vw solid cornflowerblue;
  border-right: 0.05vw solid cornflowerblue;
}

.navigation-bar {
  height: 3vw;
  width: 100%;
}

.navbar {
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navigation-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  /* width: 100%; */
  font-size: 1vw;
}

.navigation-right-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.navbar-brand {
  /* height: 1vw; */
  white-space: nowrap;
  margin-left: 1vw;
  margin-right: 1vw !important;
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
  font-size: 1.5vw !important;
}

.navbar-brand-text-label {
  font-size: 1.5vw;
  font-weight: bold;
  white-space: nowrap;
  color: orange;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.nav-link.active {
  color: rgba(0, 0, 0, 0.55) !important;
}

.nav-modal-diagram-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-copyright {
  margin-right: auto !important;
  font-size: 1.7vw;
}

.about-copyright {
  margin-right: auto !important;
  font-size: 1.5vw;
}

.nav-modal-image {
  max-width: 100%;
  max-height: 100%;
}

.nav-normal-text-label {
  padding: 0px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
}

.nav-vertical-delimiter {
  border-left: 0.1vw solid gray;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.nav-item-generic {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  max-height: 3vw;
}

.nav-link-item {
  display: flex;
  justify-content: center;
  padding: 0vw !important;
}

.link-item-hover:hover {
  cursor: pointer;
  color: cornflowerblue !important;
}
/* 
@media screen and (max-width: 992px) {
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .navigation-section {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  .navigation-right-section {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    width: 100%;
  }

  .navbar-brand {
    white-space: nowrap;
    padding-left: 2vw;
  }

  .navbar-brand-text-label {
    font-size: 1.2vw;
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
    color: orange;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

  .nav-vertical-delimiter {
    border-top: 0.1vw solid gray;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .nav-item-generic {
    margin: 0.2vw;
  }

  .nav-item-cart {
    margin: 0.2vw;
  }

  .nav-item-notifications {
    margin: 0.2vw;
  }

  .nav-item-login {
    margin: 0.2vw;
  }

  .nav-large-text-label {
    font-size: 1.2vw;
    padding: 0px;
    cursor: pointer;
    font-weight: bold;
  }

  .nav-normal-text-label {
    font-size: 1.5vw;
    padding: 0px;
    cursor: pointer;
    font-weight: bold;
  }

  .nav-small-text-label {
    font-size: 1vw;
    padding: 0px;
    color: lightgray;
    white-space: nowrap;
  }
} */
